import React from 'react'
import { FullPageHero } from '../Sections'
import Layout from '../Layout'

import { labels } from '../../langs/langs'

const ThankYou = ({ localeID, location }) => {
  return (
    <Layout location={location} title={labels[localeID]['THX']} localeID={localeID}>
      <FullPageHero localeID={localeID} blue={true} title={labels[localeID]['THX']}>
        {labels[localeID]['THX_MSG']}
      </FullPageHero>
    </Layout>
  )
}

export default ThankYou
